<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__row">
            <div class="cabinet__col">
              <h1 class="title">{{ title }}</h1>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <Loading v-if="isLoading" position="center" style="width: 100%" />
          <div v-else class="cabinet-content">
            <div class="cabinet-content__text">
              <slot />
            </div>
            <button
              v-if="hasButtonBack"
              type="button"
              class="button button-default button_big"
              @click="$router.go(-1)"
            >
              Вернуться назад
            </button>
            <slot name="foot" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import Loading from "@/components/Loading.vue";
import CommonLayout from "@/layout/CommonLayout.vue";

export default {
  props: {
    hasButtonBack: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  components: {
    CommonLayout,
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.cabinet-content {
  text-align: center;
  width: 100%;
  &__text {
    background-color: rgba(0, 255, 254, 0.03);
    width: 952px;
    min-height: 372px;
    display: grid;
    place-items: center;
    padding: 100px;
    margin: 50px auto 50px;
    color: rgba(173, 229, 217, 0.7);
    font-size: 20px;
  }
}
</style>
