<template>
  <tr>
    <td>
      <div>
        <span :title="player.playerName">{{ player.playerName }}</span>
      </div>
    </td>
    <td>
      <div
        :class="{
          green: player.grade === 5,
          yellow: player.grade === 4,
          pink: player.grade === 3,
          red: player.grade === 2,
        }"
      >
        <strong>{{ player.score !== null ? player.score : "-" }}</strong>
      </div>
    </td>
    <td>
      <div v-if="player.coins === null">
        <strong>-</strong>
      </div>
      <div v-else>
        <img src="@/assets/img/pic2/coin.svg" width="15" height="15" alt="" />{{
          player.coins
        }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    player: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped></style>
