<template>
  <div class="nav-slider goal-nav">
    <div ref="nav" class="nav-slider__container" @scroll="onScroll">
      <button
        v-if="nav.isPrev"
        class="nav-slider__button prev"
        @click="goTo('prev')"
      ></button>
      <button
        v-if="nav.isEnd"
        class="nav-slider__button next"
        @click="goTo('next')"
      ></button>
      <div class="nav-slider__inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        isPrev: false,
        isEnd: false,
      },
    };
  },
  mounted() {
    this.updateScroll(this.$refs.nav);
  },
  methods: {
    updateScroll(el) {
      const { scrollLeft, scrollWidth, clientWidth } = el;

      if (scrollLeft === clientWidth) {
        this.nav.isPrev = false;
        this.nav.isEnd = false;
        return;
      }

      const isPrev = scrollLeft > 0;
      const isEnd = scrollWidth - clientWidth - scrollLeft > 0;

      this.nav.isPrev = isPrev;
      this.nav.isEnd = isEnd;
    },
    goTo(direction) {
      let offset = 320;
      if (direction === "prev") {
        offset = offset * -1;
      }

      this.$refs.nav.scrollTo({
        behavior: "smooth",
        left: this.$refs.nav.scrollLeft + offset,
      });
    },
    onScroll(e) {
      if (e.target) {
        this.updateScroll(e.target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-slider {
  position: relative;
  &__button {
    @include reset-button;
    position: absolute;
    width: 111px;
    height: 92px;
    background-repeat: no-repeat;
    z-index: 10;
    top: -22px;
    &.prev {
      left: -14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='92' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23131B1C' d='M16.738 20 8 30.121v29.255l12.288 11.157 2.426-2.173h46.669L79 58.924v-29.26L68.998 20h-52.26Z'/%3E%3Cpath stroke='%235DFFFE' stroke-miterlimit='10' d='m78.235 29.761.153.148v28.773l-.15.147-8.966 8.797-.146.143h-46.7l-1.595 1.248.069.064.129.118 1.352-1.211.142-.128h46.655l9.322-9.146V29.876L68.795 20.5H16.967L8.5 30.307v28.848l10.135 9.202-9.86-9.065-.163-.148V30.387l.122-.14 8.172-9.482.15-.174H68.743l.145.14 9.346 9.03Zm-58.15 38.57 1.86-1.456.136-.106h46.637l8.67-8.507V30.333l-9.048-8.742H17.514L9.612 30.76v27.946l10.473 9.627Z'/%3E%3Cpath fill='%235EFFFF' d='m69.785 69.516-2.531 2.485H58.34l2.581-2.532 8.863.047ZM57.277 69.516l-2.531 2.485h-8.912l2.58-2.532 8.863.047ZM44.77 69.516l-2.533 2.485h-8.911l2.58-2.532 8.864.047ZM32.262 69.516l-2.532 2.485h-8.912l2.58-2.532 8.864.047Z'/%3E%3Cpath stroke='%235EFFFF' stroke-width='2' d='M47.322 31.934 36.4 42.782l10.923 10.847'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='111' height='92.001' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='12'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_3_574'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_3_574' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
    &.next {
      right: -14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='92' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23131B1C' d='M94.261 20 103 30.121v29.255L90.712 70.533l-2.426-2.173H41.617L32 58.924v-29.26L42.002 20h52.26Z'/%3E%3Cpath stroke='%235DFFFE' stroke-miterlimit='10' d='m32.765 29.761-.153.148v28.773l.15.147 8.966 8.797.146.143h46.7l1.595 1.248-.069.064-.129.118-1.352-1.211-.142-.128H41.822L32.5 58.714V29.876l9.705-9.376h51.828l8.467 9.807v28.848l-10.135 9.202 9.861-9.065.162-.148V30.387l-.122-.14-8.172-9.482-.15-.174H42.257l-.145.14-9.346 9.03Zm58.15 38.57-1.86-1.456-.136-.106H42.282l-8.67-8.507V30.333l9.048-8.742h50.826l7.902 9.168v27.946l-10.473 9.627Z'/%3E%3Cpath fill='%235EFFFF' d='m41.215 69.516 2.531 2.485h8.912l-2.58-2.532-8.863.047ZM53.723 69.516l2.531 2.485h8.912l-2.58-2.532-8.863.047ZM66.23 69.516l2.532 2.485h8.912l-2.58-2.532-8.863.047ZM78.738 69.516l2.532 2.485h8.912l-2.58-2.532-8.864.047Z'/%3E%3Cpath stroke='%235EFFFF' stroke-width='2' d='M63.678 31.934 74.6 42.782 63.678 53.629'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='111' height='92.001' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='-12'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_3_565'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_3_565' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
  }
  &__container {
    max-width: 1230px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__inner {
    display: inline-grid;
    grid-auto-flow: column;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='1138' height='44' viewBox='0 0 1138 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1056.43.2129h76.42l5.15 5.5977v27.0702l-7.92 8.3748h-23.07l-2.64 2.1298L50.0001 43.5l-4.3743-3.6912H8.5636L.9259 32.1542V8.4177L8.8695.578H68.392L1056.43.2129Zm.36.8854-988.7639.3652H9.2315l-7.4222 7.325v22.9989l7.1202 7.136h37.0622l4.3743 3.6912L1104 42.5l2.64-2.1298h23.07l7.41-7.8562V6.1774l-4.64-5.0791h-75.69Z' fill='%23628D8D'/%3E%3C/svg%3E");
      content: "";
      pointer-events: none;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      background-position: 100% 0;
    }
  }
  &__item {
    display: flex;
    scroll-snap-align: start;
    a.active,
    a.router-link-exact-active {
      color: #5effff;
      &::before,
      &::after {
        position: absolute;
        top: 0px;
        width: 50%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='423' height='44' fill='none'%3E%3Cpath fill='%2300FFFE' fill-opacity='.15' fill-rule='evenodd' d='M136.453 0h281.4L423 5.626V32.83l-7.917 8.417h-23.066L389.511 43H50.057l-4.372-3.207H8.635L1 32.1V8.246L8.94.367h59.503L136.453 0Z' clip-rule='evenodd' opacity='.7'/%3E%3Cpath fill='%235EFFFF' d='m8.244 40.746 2.01 2.015h7.078l-2.05-2.054-7.038.039ZM18.178 40.746l2.01 2.015h7.078l-2.05-2.054-7.038.039ZM28.11 40.746l2.01 2.015h7.077l-2.049-2.054-7.039.039ZM38.043 40.746l2.01 2.015h7.078l-2.05-2.054-7.038.039ZM335.965 4.261l2.011-2.015h7.077L343.004 4.3l-7.039-.039ZM345.898 4.261l2.011-2.015h7.078l-2.05 2.054-7.039-.039ZM355.832 4.261l2.011-2.015h7.077L362.871 4.3l-7.039-.039ZM365.764 4.261l2.01-2.015h7.078l-2.05 2.054-7.038-.039ZM391.646 38.956l2.011-2.015h7.078l-2.05 2.054-7.039-.039ZM401.58 38.956l2.011-2.015h7.077l-2.049 2.054-7.039-.039Z'/%3E%3Cpath fill='%235EFFFF' fill-rule='evenodd' d='M136.425.211h281.309l5.149 5.598v27.07l-7.92 8.375H391.89l-2.641 2.13L50 43.497l-4.374-3.69H8.563L.926 32.152V8.416L8.869.576h59.523l68.033-.365Zm.366.886-68.765.365H9.231L1.81 8.787v22.999l7.12 7.136h37.063l4.374 3.69 338.517-.114 2.641-2.13h23.073L422 32.512V6.175l-4.632-5.079H136.791Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        content: "";
        z-index: 1;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
        background-position: 100% 0;
      }
    }
    a {
      @include reset-button;

      display: flex;
      max-width: 320px;
      height: 42px;
      padding: 0 25px;
      text-decoration: none;
      height: 44px;
      position: relative;
      font-size: 20px;
      line-height: 23px;
      color: #628d8d;
      align-items: center;
      text-decoration: none;
      min-width: 140px;
      text-align: center;
      justify-content: center;
      &::v-deep span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-bottom: 4px;
      }
    }
  }
}
</style>
